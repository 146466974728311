import React from 'react';
import styled, { useTheme } from 'styled-components';
import zxcvbn from 'zxcvbn';
import { Progress } from './ProgressBar';
import { useTranslation } from 'react-i18next';

export type Rule = {
  name: string;
  validate: (password: string) => boolean;
  message: string;
};

type PasswordStrengthProps = {
  password: string;
  rules: Rule[];
};

const RulesContainer = styled.div`
  margin-top: 8px;
`;

const RuleRow = styled.div<{ $fulfilled: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 8px;
  color: ${({ $fulfilled }) => (!$fulfilled ? '#AC2A2F' : '#161C22')};
`;

const FulfilledIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export function PasswordStrength({
  password,
  rules
}: Readonly<PasswordStrengthProps>) {
  const {
    screenAndPagesContentConfiguration: {
      passwordMgtContentConfiguration: config
    } = {}
  } = useTheme();

  const { t } = useTranslation();

  const STRENGTH_SCORE_MAP = {
    0: t('passwordStrength.veryWeak'),
    1: t('passwordStrength.weak'),
    2: t('passwordStrength.fair'),
    3: t('passwordStrength.good'),
    4: t('passwordStrength.excellent')
  };

  const result = zxcvbn(password);

  return (
    <div style={{ width: '100%' }}>
      <Progress value={result.score} max={4} />
      <RulesContainer>
        {t('passwordStrength.title')}{' '}
        <b>
          {config?.strengthScoreValues?.[result.score] ||
            STRENGTH_SCORE_MAP[result.score]}
        </b>
        {rules.map(rule => (
          <RuleRow key={rule.name} $fulfilled={rule.validate(password)}>
            <FulfilledIcon
              src={
                rule.validate(password)
                  ? config?.requirementFulfilledIcon?.src || '/portal/done.svg'
                  : config?.requirementUnfulfilledIcon?.src ||
                    '/portal/close.svg'
              }
              alt="status"
            />{' '}
            {rule.message}
          </RuleRow>
        ))}
      </RulesContainer>
    </div>
  );
}
